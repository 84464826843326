
import { Vue } from 'vue-class-component';
import {computed} from "vue";

const KEY_HIGHSCORE = 'com.puzzleyo.monkeysee.highscore';

export default class Game extends Vue {
  rows = 10;
  cols = 10;
  cells: Array<Array<number>> = [];
  guessed: Array<number> = [];
  time = 0;
  timeMs = 0;
  score = 0;
  highScore = 0;
  timer = -1;
  status = 'playing';

  nextNumber = computed(() => this.guessed.findIndex(g => g === 0) + 1);

  timeFmt = computed(() => {
    let seconds = this.time % 60;
    return Math.floor(this.time / 60) + ':' + (seconds < 10 ? '0' : '') + seconds;
  });

  cellClass(row: number, col: number): string {
    let cell = this.cells[row][col];
    if (this.guessed.every(g => g === 0)) {
      if (cell === 1) {
        return 'tostart show';
      } else if (cell) {
        return 'show';
      } else {
        return 'empty';
      }
    } else {
      if (this.guessed[cell - 1] === 1) {
        return 'guessed';
      } else if (this.guessed[cell - 1] === 2) {
        return 'missed';
      } else if (cell) {
        return 'toguess';
      } else {
        return 'empty';
      }
    }
  }

  clickCell(row: number, col: number): void {
    let cell = this.cells[row][col] - 1;
    if (this.guessed[0] === 0) {
      if (cell === 0) {
        this.guessed[0] = 1;
        this.time = 0;
        this.incTime();
      }
    } else if (cell === -1) {
      return;
    } else if (this.guessed
        .filter((value, index) => index < cell)
        .every(g => g === 1)) {
      this.guessed[cell] = 1;
      if (cell === 8) {
        this.stopTimer();
        this.status = 'won';
      }
    } else {
      this.guessed[cell] = 2;
      this.stopTimer();
      this.status = 'lost';
    }
  }

  incTime(): void {
    this.timer = setInterval(() => {
      this.time++;
    }, 1000);
  }

  stopTimer(): void {
    clearInterval(this.timer);
    this.score = Math.floor(100000 / (Date.now() - this.timeMs));
    this.setHighScore();
  }

  setHighScore(): void {
    let highScore = parseInt(localStorage.getItem(KEY_HIGHSCORE) ?? '0');
    if (highScore < this.score) {
      highScore = this.score;
      localStorage.setItem(KEY_HIGHSCORE, highScore.toString())
    }
    this.highScore = highScore;
  }

  clickBoard(): void {
    if (this.status === 'won' || this.status === 'lost') {
      this.initGame();
    }
  }

  initGame(): void {
    this.status = 'playing';
    this.time = 0;
    this.timeMs = Date.now();
    this.score = 0;
    this.guessed = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.cells = [
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    ];
    for (let number = 1; number <= 9; number++) {
      while (true) {
        let row = Math.floor(Math.random() * 9);
        let col = Math.floor(Math.random() * 9);
        if (this.cells[row][col] === 0) {
          this.cells[row][col] = number;
          break;
        }
      }
    }
  }

  beforeMount(): void {
    this.initGame();
  }
}
