
import { Options, Vue } from 'vue-class-component';
import Game from "@/components/Game.vue";
import "./assets/style.scss";

@Options({
  components: {
    Game,
  },
})
export default class App extends Vue {}
